import React from 'react';
import '../css/AboutUsDescription.css'

const ContactCard = () => {
  return (
    <div className="container-aboutusDesc">
      <div className="centered-section-aboutusDesc">
        <h2><span className='span-text-aboutusDesc'>Contact</span> using following Details!!!</h2>
        <p>About GSPrimo Description goes here.</p>
      </div>
    </div>
  );
};

export default ContactCard;